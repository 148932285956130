<template>
  <div class="container">
    <div v-if="token" class="header-item">
      <div class="header"><img :src="userInfo.header || defaultHeader" /></div>
      <div class="nickname">{{ userInfo.nickname || userInfo.phone }}</div>
      <!-- <div class="change-btn" @click="handleChangeAccount">更换账号</div> -->
    </div>
    <div v-else class="header-item" @click="handleToLogin">
      <div class="header"><img :src="defaultHeader" /></div>
      <div class="nickname">点击登录</div>
    </div>
    <div class="nav-list-item">
      <div class="nav" :class="orderType == 2 ? 'active' : ''" @click="handleChangeType(2)">开通会员<div class="line"></div></div>
      <div class="nav" :class="orderType == 1 ? 'active' : ''" @click="handleChangeType(1)">充值钻石<div class="line"></div></div>
    </div>
    <div v-if="orderType == 2" class="goods-list-item">
      <div v-for="(item, index) in vipList" :key="index" class="goods-item" :class="checkedItem.id == item.id ? 'active' : ''" @click="handleChangeCheckedId(item)">
        <div class="title">{{ item.title }}</div>
        <div class="money">{{ item.money }}元</div>
      </div>
    </div>
    <div v-if="orderType == 1" class="goods-list-item">
      <div v-for="(item, index) in zsList" :key="index" class="goods-item" :class="checkedItem.id == item.id ? 'active' : ''" @click="handleChangeCheckedId(item)">
        <div class="title"><img :src="checkedItem.id == item.id ? zsActiveIcon : zsIcon" />{{ item.zs_count }}</div>
        <div class="money">{{ item.money }}元</div>
      </div>
    </div>
    <div v-if="orderType == 1" class="xy-item">充值即表示同意偶遇圈<span @click="handleToXy(7)">《用户服务协议》</span></div>
    <div v-if="orderType == 2" class="xy-item">开通即表示同意偶遇圈<span @click="handleToXy(7)">《用户服务协议》</span></div>
    <div class="pay-type-list">
      <div class="pay-type" :class="payType == 2 ? 'active' : ''" @click="handleChangePayType(2)">
        <img src="../../assets/images/mobile/zfb-icon.png" class="icon" />
        <div class="name">支付宝支付</div>
        <div class="select-box"><img src="../../assets/images/mobile/check-icon.png" class="icon" /></div>
      </div>
      <div v-if="isWeChat" class="pay-type" :class="payType == 1 ? 'active' : ''" @click="handleChangePayType(1)">
        <img src="../../assets/images/mobile/wx-icon.png" class="icon" />
        <div class="name">微信支付</div>
        <div class="select-box"><img src="../../assets/images/mobile/check-icon.png" class="icon" /></div>
      </div>
    </div>
    <div class="btn" @click="handleCreateOrder"><van-loading v-if="submiting" color="#333333" />{{ orderType == 1 ? '立即充值' : '立即开通' }}</div>
    <div v-if="showZfbPopup" class="zfb-popup" @click="handlechangeShowZfbPopup">
      <wechat-browser-tips-component type="pay" @handleHideWechatBrowserTips="handleHideWechatBrowserTips"></wechat-browser-tips-component>
    </div>
    <van-popup v-model="loginVis" ><!-- @click="closeLogin()" -->
      <LoginM />
    </van-popup>
  </div>
</template>

<script>

import { vipList, zsList, createOrder, pay, wxConfig } from '@/api/index'
import { mapState, mapMutations } from 'vuex'
import Wx from 'weixin-js-sdk'
import { defineAsyncComponent, ref } from 'vue';
import WechatBrowserTipsComponent from '../../components/WechatBrowserTipsComponent.vue'

export default {
  name: 'Recharge',
  components: {
    LoginM: defineAsyncComponent(() => import('./Login.vue')),
    WechatBrowserTipsComponent
  },
  setup() {
    const loginVis = ref(false);
    const showLogin = () => {
      console.log("showlogin");
      loginVis.value = true;
    };
    return {
      loginVis,
      showLogin,
    };
  },
  data () {
    return {
      orderType: 2, // 2:vip, 1:钻石
      payType: 2, // 2:支付宝, 1:微信
      checkedItem: {},
      vipList: [],
      zsList: [],
      submiting: false,
      defaultHeader: require('../../assets/images/mobile/default-header.png'),
      zsIcon: require('../../assets/images/mobile/sz-icon.png'),
      zsActiveIcon: require('../../assets/images/mobile/sz-active-icon.png'),
      showZfbPopup: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.orderType = to.query.order_type || 2
      vm.handleGetPageData()
    })
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    isWeChat() {
      return /MicroMessenger/i.test(navigator.userAgent);
    },
  },
  mounted () {
    wxConfig({
      url: window.location.href
    }).then(res => {
      if (res.error === 0) {
        const data = res.info
        Wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timeStamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: ['chooseWXPay'] // 必填，需要使用的 JS 接口列表
        })
      }
    })
  },
  methods: {
    ...mapMutations(['SET_TOKEN', 'SET_USERINFO']),
    handleToXy (id) {
      this.$router.push({ path: '/agreement', query: { id } })
    },
    handleChangeAccount () {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '您确定要切换账号吗?'
      }).then(() => {
        this.SET_TOKEN('')
        this.SET_USERINFO({})
        this.handleToLogin()
      })
    },
    handleToLogin () {
      const { isWeChat } = this
      if (isWeChat) {
        window.location.href = '/api/gongCode'
      } else {
        //this.$router.push({ path: '/login' })
        this.loginVis = true
      }
    },
    handleGetPageData () {
      vipList({
        device: 3
      }).then(res => {
        this.vipList = res.info
        if (this.orderType === 2) {
          this.checkedItem = res.info[0]
        }
      })
      zsList({
        device: 3
      }).then(res => {
        this.zsList = res.info
        if (this.orderType === 1) {
          this.checkedItem = res.info[0]
        }
      })
    },
    handleChangeCheckedId (item) {
      this.checkedItem = {
        ...item
      }
    },
    handleChangeType (type) {
      const { vipList, zsList } = this
      this.orderType = type
      if (type === 1) {
        this.checkedItem = zsList[0]
      } else {
        this.checkedItem = vipList[0]
      }
    },
    handlechangeShowZfbPopup () {
      const { showZfbPopup } = this
      this.showZfbPopup = !showZfbPopup
    },
    handleHideWechatBrowserTips () {
      this.showZfbPopup = false
    },
    handleChangePayType (type) {
      this.payType = type
    },
    handleCreateOrder () {
      const { token, orderType, payType, checkedItem, submiting, userInfo, isWeChat } = this
      if (submiting) {
        return
      }
      if (isWeChat && payType === 2) {
        this.showZfbPopup = true
        return
      }
      if (!token) {
        this.handleToLogin()
        return
      }
      if (!checkedItem.id) {
        return
      }
      const params = {
        order_type: orderType,
        pay_type: payType,
        goods_id: checkedItem.id,
        pay_where: 2,
        gong_open_id: userInfo.open_id
      }
      this.submiting = true
      createOrder(params).then(res => {
        if (res.error === 0) {
          this.handlePay(res.info)
        } else {
          this.submiting = false
          this.$dialog.alert({
            message: res.info
          })
        }
      })
    },
    handlePay (id) {
      const { payType } = this
      pay({ id }).then(res => {
        this.submiting = false
        if (res.error === 0) {
          if (payType === 1) {
            const data = JSON.parse(res.info.js)
            this.handleCreateWxPay(data)
          } else {
            window.location.href = res.info
          }
        } else {
          this.$dialog.alert({
            message: res.info
          })
        }
      })
    },
    handleCreateWxPay (data) {
      Wx.chooseWXPay({
        appId: data.appId,
        timestamp: data.timeStamp,
        nonceStr: data.nonceStr,
        package: data.package,
        signType: data.signType,
        paySign: data.paySign,
        success: (res) => {
          console.log('--------res--------')
          console.log(res)
          this.$dialog.alert({
            message: '支付成功'
          })
        },
        fail: (err) => {
          console.log('--------err--------')
          console.log(err)
          this.$dialog.alert({
            message: '支付失败 请重试'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.van-popup {
  width: 426px;
  height: 438px;
  border-radius: 20px;
  background: transparent;
  .container {
    background: none;
  }
}

.container {
  padding: 0 30rem 100rem;
}
.header-item {
  padding: 46rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header {
    width: 112rem;
    height: 112rem;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .nickname {
    margin-left: 16rem;
    font-size: 28rem;
    color: #333;
    flex: 1;
  }
  .change-btn {
    width: 150rem;
    height: 60rem;
    border: 2rem solid #eee;
    border-radius: 30rem;
    font-size: 24rem;
    color: #333;
    text-align: center;
    line-height: 60rem;
  }
}
.nav-list-item {
  display: flex;
  align-items: center;
  .nav {
    font-size: 32rem;
    font-weight: 600;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 90rem;
    .line {
      width: 38rem;
      height: 8rem;
      border-radius: 4rem;
      margin-top: 10rem;
    }
    &.active {
      .line {
        background-color: #333;
      }
    }
  }
}
.goods-list-item {
  margin-top: 30px;
  overflow: hidden;
  .goods-item {
    float: left;
    width: 212rem;
    height: 192rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4rem solid transparent;
    background: #f7f7f7;
    border-radius: 8rem;
    margin-right: 14rem;
    margin-bottom: 16rem;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &.active {
      background: #fbfbef;
      background-size: 27% 27%;
      border-color: #f7e13d;
      &::after {
        content: '.';
        width: 50rem;
        text-align: center;
        line-height: 34rem;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #f7e13d;
        background: url(../../assets/images/mobile/charge-check.png) bottom right no-repeat;
        background-size: cover;
        font-size: 0.2rem;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom: 50rem solid #f7e13d;
        border-left: 50rem solid transparent;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333;
      font-size: 40rem;
      font-weight: 600;
      img {
        width: 28rem;
        height: 24rem;
        margin-right: 2rem;
      }
    }
    .money {
      margin-top: 24rem;
      font-size: 24rem;
      color: #bdbdbd;
    }
  }
}
.xy-item {
  padding: 54rem 0;
  text-align: center;
  font-size: 24rem;
  color: #333;
  span {
    color: #ff7d1a;
  }
}
.pay-type-list {
  padding: 0 30rem;
  .pay-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30rem 0;
    &.active {
      .select-box {
        width: 26rem;
        height: 26rem;
        border-color: #f7e13d;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        /*border-color: #f7e13d;
        background-color: #f7e13d;
        &::after {
          content: '✓';
          width: 26rem;
          height: 26rem;
          text-align: center;
          line-height: 26rem;
          position: absolute;
          left: 0;
          top: 0;
          color: #333;
          font-size: 20rem;
        }*/
      }
    }
    img {
      width: 50rem;
      height: 50rem;
    }
    .name {
      padding: 0 22rem;
      font-size: 28rem;
      color: #333;
      flex: 1;
    }
    .select-box {
      width: 26rem;
      height: 26rem;
      border: 2rem solid #b0b0b3;
      border-radius: 50%;
      position: relative;
      img {
        display: none;
      }
    }
  }
}
.btn {
  margin-top: 200rem;
  width: 100%;
  height: 96rem;
  background-color: #f7e13d;
  border-radius: 48rem;
  color: #333;
  font-size: 28rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zfb-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6);
  img {
    position: absolute;
    right: 80rem;
    top: 50rem;
    width: 200rem;
  }
  .tip {
    color: #fff;
    font-size: 28rem;
    position: absolute;
    top: 280rem;
    right: 100rem;
  }
}
</style>
